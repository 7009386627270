/* eslint-disable */
const siteColors = [
    {
        "name": "color_1",
        "value": "#FFFFFF",
        "reference": "white/black"
    },
    {
        "name": "color_2",
        "value": "#000000",
        "reference": "black/white"
    },
    {
        "name": "color_3",
        "value": "#ED1C24",
        "reference": "primery-1"
    },
    {
        "name": "color_4",
        "value": "#0088CB",
        "reference": "primery-2"
    },
    {
        "name": "color_5",
        "value": "#FFCB05",
        "reference": "primery-3"
    },
    {
        "name": "color_11",
        "value": "#FFFFFF",
        "reference": "color-1"
    },
    {
        "name": "color_12",
        "value": "#CFCCCC",
        "reference": "color-2"
    },
    {
        "name": "color_13",
        "value": "#969696",
        "reference": "color-3"
    },
    {
        "name": "color_14",
        "value": "#646464",
        "reference": "color-4"
    },
    {
        "name": "color_15",
        "value": "#000000",
        "reference": "color-5"
    },
    {
        "name": "color_16",
        "value": "#CFE5FF",
        "reference": "color-6"
    },
    {
        "name": "color_17",
        "value": "#8CBAFF",
        "reference": "color-7"
    },
    {
        "name": "color_18",
        "value": "#166AEA",
        "reference": "color-8"
    },
    {
        "name": "color_19",
        "value": "#00469C",
        "reference": "color-9"
    },
    {
        "name": "color_20",
        "value": "#23327B",
        "reference": "color-10"
    },
    {
        "name": "color_21",
        "value": "#F9C5B4",
        "reference": "color-11"
    },
    {
        "name": "color_22",
        "value": "#F3A78F",
        "reference": "color-12"
    },
    {
        "name": "color_23",
        "value": "#ED5829",
        "reference": "color-13"
    },
    {
        "name": "color_24",
        "value": "#9E3B1B",
        "reference": "color-14"
    },
    {
        "name": "color_25",
        "value": "#4F1D0E",
        "reference": "color-15"
    },
    {
        "name": "color_26",
        "value": "#D2ACF7",
        "reference": "color-16"
    },
    {
        "name": "color_27",
        "value": "#BA83F0",
        "reference": "color-17"
    },
    {
        "name": "color_28",
        "value": "#8015E8",
        "reference": "color-18"
    },
    {
        "name": "color_29",
        "value": "#550E9B",
        "reference": "color-19"
    },
    {
        "name": "color_30",
        "value": "#2B074D",
        "reference": "color-20"
    },
    {
        "name": "color_31",
        "value": "#B1D3BB",
        "reference": "color-21"
    },
    {
        "name": "color_32",
        "value": "#7FA88B",
        "reference": "color-22"
    },
    {
        "name": "color_33",
        "value": "#407C51",
        "reference": "color-23"
    },
    {
        "name": "color_34",
        "value": "#2B5336",
        "reference": "color-24"
    },
    {
        "name": "color_35",
        "value": "#15291B",
        "reference": "color-25"
    }
];
const siteTextPresets = {
    "Title": {
        "editorKey": "font_0",
        "lineHeight": "1.4em",
        "style": "normal",
        "weight": "normal",
        "size": "48px",
        "fontFamily": "madefor-text-mediumbold,helveticaneuew01-45ligh,helveticaneuew02-45ligh,helveticaneuew10-45ligh,sans-serif",
        "value": "font:normal normal normal 48px/1.4em madefor-text-mediumbold,helveticaneuew01-45ligh,helveticaneuew02-45ligh,helveticaneuew10-45ligh,sans-serif;"
    },
    "Menu": {
        "editorKey": "font_1",
        "lineHeight": "1.4em",
        "style": "normal",
        "weight": "normal",
        "size": "16px",
        "fontFamily": "din-next-w01-light,din-next-w02-light,din-next-w10-light,sans-serif",
        "value": "font:normal normal normal 16px/1.4em din-next-w01-light,din-next-w02-light,din-next-w10-light,sans-serif;"
    },
    "Page-title": {
        "editorKey": "font_2",
        "lineHeight": "1.4em",
        "style": "normal",
        "weight": "normal",
        "size": "32px",
        "fontFamily": "madefor-text-mediumbold,helveticaneuew01-45ligh,helveticaneuew02-45ligh,helveticaneuew10-45ligh,sans-serif",
        "value": "font:normal normal normal 32px/1.4em madefor-text-mediumbold,helveticaneuew01-45ligh,helveticaneuew02-45ligh,helveticaneuew10-45ligh,sans-serif;"
    },
    "Heading-XL": {
        "editorKey": "font_3",
        "lineHeight": "1.4em",
        "style": "normal",
        "weight": "normal",
        "size": "28px",
        "fontFamily": "madefor-text-mediumbold,helveticaneuew01-45ligh,helveticaneuew02-45ligh,helveticaneuew10-45ligh,sans-serif",
        "value": "font:normal normal normal 28px/1.4em madefor-text-mediumbold,helveticaneuew01-45ligh,helveticaneuew02-45ligh,helveticaneuew10-45ligh,sans-serif;"
    },
    "Heading-L": {
        "editorKey": "font_4",
        "lineHeight": "1.4em",
        "style": "normal",
        "weight": "normal",
        "size": "24px",
        "fontFamily": "madefor-text-mediumbold,helveticaneuew01-45ligh,helveticaneuew02-45ligh,helveticaneuew10-45ligh,sans-serif",
        "value": "font:normal normal normal 24px/1.4em madefor-text-mediumbold,helveticaneuew01-45ligh,helveticaneuew02-45ligh,helveticaneuew10-45ligh,sans-serif;"
    },
    "Heading-M": {
        "editorKey": "font_5",
        "lineHeight": "1.4em",
        "style": "normal",
        "weight": "normal",
        "size": "20px",
        "fontFamily": "madefor-text-mediumbold,helveticaneuew01-45ligh,helveticaneuew02-45ligh,helveticaneuew10-45ligh,sans-serif",
        "value": "font:normal normal normal 20px/1.4em madefor-text-mediumbold,helveticaneuew01-45ligh,helveticaneuew02-45ligh,helveticaneuew10-45ligh,sans-serif;"
    },
    "Heading-S": {
        "editorKey": "font_6",
        "lineHeight": "1.4em",
        "style": "normal",
        "weight": "normal",
        "size": "16px",
        "fontFamily": "madefor-text-mediumbold,helveticaneuew01-45ligh,helveticaneuew02-45ligh,helveticaneuew10-45ligh,sans-serif",
        "value": "font:normal normal normal 16px/1.4em madefor-text-mediumbold,helveticaneuew01-45ligh,helveticaneuew02-45ligh,helveticaneuew10-45ligh,sans-serif;"
    },
    "Body-L": {
        "editorKey": "font_7",
        "lineHeight": "1.4em",
        "style": "normal",
        "weight": "normal",
        "size": "20px",
        "fontFamily": "wfont_ebd955_e452130b593f4a27b692b74fd0437112,wf_e452130b593f4a27b692b74fd,orig_wix_madefor_text_regular,\"Noto Sans Hebrew\"",
        "value": "font:normal normal normal 20px/1.4em wfont_ebd955_e452130b593f4a27b692b74fd0437112,wf_e452130b593f4a27b692b74fd,orig_wix_madefor_text_regular,Noto Sans Hebrew"
    },
    "Body-M": {
        "editorKey": "font_8",
        "lineHeight": "1.4em",
        "style": "normal",
        "weight": "normal",
        "size": "16px",
        "fontFamily": "wfont_ebd955_e452130b593f4a27b692b74fd0437112,wf_e452130b593f4a27b692b74fd,orig_wix_madefor_text_regular,\"Noto Sans Hebrew\"",
        "value": "font:normal normal normal 16px/1.4em wfont_ebd955_e452130b593f4a27b692b74fd0437112,wf_e452130b593f4a27b692b74fd,orig_wix_madefor_text_regular,Noto Sans Hebrew"
    },
    "Body-S": {
        "editorKey": "font_9",
        "lineHeight": "1.4em",
        "style": "normal",
        "weight": "normal",
        "size": "14px",
        "fontFamily": "wfont_ebd955_e452130b593f4a27b692b74fd0437112,wf_e452130b593f4a27b692b74fd,orig_wix_madefor_text_regular,\"Noto Sans Hebrew\"",
        "value": "font:normal normal normal 14px/1.4em wfont_ebd955_e452130b593f4a27b692b74fd0437112,wf_e452130b593f4a27b692b74fd,orig_wix_madefor_text_regular,Noto Sans Hebrew"
    },
    "Body-XS": {
        "editorKey": "font_10",
        "lineHeight": "1.4em",
        "style": "normal",
        "weight": "normal",
        "size": "12px",
        "fontFamily": "din-next-w01-light,din-next-w02-light,din-next-w10-light,sans-serif",
        "value": "font:normal normal normal 12px/1.4em din-next-w01-light,din-next-w02-light,din-next-w10-light,sans-serif;"
    }
};

export const checkoutTheme = {siteColors, siteTextPresets};
