import React, {useEffect} from 'react';
import {useControllerProps} from '../Widget/ControllerContext';
import {CustomField} from './CustomField/CustomField';
import {ShippingDetails} from './ShippingDetails/ShippingDetails';
import {DeliveryMethodFastFlow} from './DeliveryMethodFastFlow/DeliveryMethodFastFlow';
import {Text} from 'wix-ui-tpa';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {classes} from './PlaceOrderFastFlow.st.css';
import {FastFlowAnalyticsEventParams} from '../../../domain/utils/analytics.utils';
import {Checkboxes} from '../Checkboxes/Checkboxes';
import {ErrorDialog} from '../ErrorDialog/ErrorDialog';
import {ViolationsAndPlaceOrderButton} from '../ViolationsAndPlaceOrderButton/ViolationsAndPlaceOrderButton';
import {ViolationsAndPlaceOrderButtonLocation} from '../../../types/app.types';

export enum PlaceOrderFastFlowDataHook {
  root = 'PlaceOrderFastFlow.root',
  title = 'PlaceOrderFastFlow.title',
  violations = 'PlaceOrderFastFlow.violations',
}

export const PlaceOrderFastFlow = () => {
  const {
    checkoutStore: {isMissingCustomField, placeOrderError, checkout},
    navigationStore: {trackEvent},
    checkboxesStore: {shouldShowCheckboxesSection},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();

  useEffect(() => {
    trackEvent(...FastFlowAnalyticsEventParams);
  }, [trackEvent]);

  return (
    <div data-hook={PlaceOrderFastFlowDataHook.root}>
      <Text tagName={'h2'} className={classes.title} data-hook={PlaceOrderFastFlowDataHook.title}>
        {localeKeys.checkout.place_order_fast_flow.title()}
      </Text>
      <ShippingDetails />
      <DeliveryMethodFastFlow />
      <div
        className={isMissingCustomField || shouldShowCheckboxesSection ? classes.section : classes.buttonOnlySection}>
        {isMissingCustomField && <CustomField />}
        <Checkboxes />
        <ViolationsAndPlaceOrderButton location={ViolationsAndPlaceOrderButtonLocation.fastFlow} />
      </div>
      {(checkout.errors.noItemsError || placeOrderError) && <ErrorDialog />}
    </div>
  );
};
