import React, {useEffect, useRef} from 'react';
import {Text} from 'wix-ui-tpa';
import {Checkboxes} from '../../../../Checkboxes/Checkboxes';
import {classes} from './PlaceOrderOpen.st.css';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {PlaceOrderDataHook} from '../PlaceOrderStep';
import {ViolationsAndPlaceOrderButton} from '../../../../ViolationsAndPlaceOrderButton/ViolationsAndPlaceOrderButton';
import {ViolationsAndPlaceOrderButtonLocation} from '../../../../../../types/app.types';

export const PlaceOrderOpen = () => {
  const localeKeys = useLocaleKeys();
  const sectionRootRef = useRef<HTMLDivElement>(null);

  useEffect(
    () => {
      scrollToSectionRoot();
    },
    /* eslint-disable react-hooks/exhaustive-deps */ []
  );

  const scrollToSectionRoot = () => {
    sectionRootRef?.current?.scrollIntoView({block: 'center'});
  };

  return (
    <div className={classes.root} data-hook={PlaceOrderDataHook.open} ref={sectionRootRef}>
      <div className={classes.subtitle}>
        <Text data-hook={PlaceOrderDataHook.subtitle}>{localeKeys.checkout.place_order.description()}</Text>
      </div>
      <Checkboxes />
      <ViolationsAndPlaceOrderButton location={ViolationsAndPlaceOrderButtonLocation.placeOrderStep} />
    </div>
  );
};
