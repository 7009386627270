import {FIELDS_GAP, FORMS_TEMPLATE_IDS} from '../../constants';
import {FormViewer} from '@wix/form-viewer/widget';
import React from 'react';
import {FormProps} from '../ContactForm/ContactForm';
import {useICUI18n} from '../../Hooks/useICUI18n';

const ExtendedFieldsFormInternal = ({formRef, formValues, setFormValues, formErrors, setFormErrors}: FormProps) => {
  const i18n = useICUI18n();

  return (
    <FormViewer
      i18n={i18n}
      ref={formRef}
      formId={FORMS_TEMPLATE_IDS.EXTENDED_FIELDS_FORM_ID}
      values={formValues}
      errors={formErrors}
      onChange={setFormValues}
      onValidate={setFormErrors}
      style={{
        styleParams: {
          numbers: {
            rowSpacing: FIELDS_GAP,
          },
        },
      }}
    />
  );
};

export const ExtendedFieldsForm = React.memo(ExtendedFieldsFormInternal);
