import {CustomerDetailsOpen} from './Components/CustomerDetailsOpen';
import {CustomerDetailsCollapsed} from './Components/CustomerDetailsCollapsed';
import React from 'react';
import {Step} from '../../StepsManager/Components/Step';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {StepImplementationProps} from '../../../../../types/app.types';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {withCustomerDetailsData} from './WithCustomerDetailsData/WithCustomerDetailsData';
import {withMemberDetailsData} from '../../../MemberDetails/WithMemberDetailsData';
import {SPECS} from '../../../constants';
import {useExperiments} from '@wix/yoshi-flow-editor';

export enum CustomerDetailsStepDataHook {
  root = 'CustomerDetailsStep.root',
  collapsed = 'CustomerDetailsStep.collapsed',
  open = 'CustomerDetailsStep.open',
  empty = 'CustomerDetailsStep.empty',
}

export const CustomerDetailsStep = ({index}: StepImplementationProps) => {
  const {experiments} = useExperiments();
  const localeKeys = useLocaleKeys();
  const {
    checkoutStore: {isShippingFlow},
  } = useControllerProps();

  const getShippingDeliveryTitle = (): string => {
    return experiments.enabled(SPECS.DeliveryOverShippingText)
      ? localeKeys.checkout.delivery_details_title()
      : localeKeys.checkout.shipping_details_title();
  };
  return (
    <Step
      index={index!}
      title={isShippingFlow ? getShippingDeliveryTitle() : localeKeys.checkout.customer_details()}
      dataHook={CustomerDetailsStepDataHook.root}
      openComponent={WrappedCustomerDetailsOpenStep}
      collapsedComponent={CustomerDetailsCollapsed}
    />
  );
};

const WrappedCustomerDetailsOpenStep = withMemberDetailsData(
  withCustomerDetailsData(CustomerDetailsOpen),
  (checkout) => checkout.shippingDestination,
  'validateShippingAddress'
);
