import React, {ReactNode} from 'react';
import {RadioButton, RadioButtonTheme} from 'wix-ui-tpa';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {DeliveryMethodPickerDataHook} from './DeliveryMethodPicker';
import {classes} from './DeliveryMethodPicker.st.css';
import {ShippingOptionModel} from '../../../../domain/models/ShippingOption.model';

export interface DeliveryMethodProps {
  option: ShippingOptionModel;
  onChange?: () => void;
  selectedId?: string;
  subtext?: string;
  disabled?: boolean;
  selectedDetailSection?: () => ReactNode;
}

export const DeliveryMethod = ({
  option,
  onChange,
  selectedId,
  subtext,
  selectedDetailSection,
  disabled,
}: DeliveryMethodProps) => {
  const localeKeys = useLocaleKeys();
  const isFree = option.price?.amount === 0;
  const isChecked = selectedId === option.code;

  return (
    <>
      <RadioButton
        data-hook={DeliveryMethodPickerDataHook.shippingOption}
        className={classes.radioButton}
        value={option.code}
        checked={isChecked}
        label={option.title}
        subtext={subtext}
        disabled={disabled}
        suffix={
          <span data-hook={DeliveryMethodPickerDataHook.shippingOptionPrice} className={classes.priceTag}>
            {isFree ? localeKeys.checkout.delivery_method.free_label() : option.price.formattedAmount}
          </span>
        }
        onChange={onChange}
        theme={RadioButtonTheme.Box}
      />
      {isChecked && selectedDetailSection?.()}
    </>
  );
};
