import {DeliveryMethodEmpty} from './Components/DeliveryMethodEmpty';
import {DeliveryMethodOpen} from './Components/DeliveryMethodOpen';
import {DeliveryMethodCollapsed} from './Components/DeliveryMethodCollapsed';
import React from 'react';
import {Step} from '../../StepsManager/Components/Step';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {StepImplementationProps} from '../../../../../types/app.types';
import {useControllerProps} from '../../../Widget/ControllerContext';

export enum DeliveryMethodStepDataHook {
  root = 'DeliveryMethodStep.root',
  collapsed = 'DeliveryMethodStep.collapsed',
  open = 'DeliveryMethodStep.open',
  empty = 'DeliveryMethodStep.empty',
  continueButton = 'DeliveryMethodStep.continue',
}

export const DeliveryMethodStep = ({index}: StepImplementationProps) => {
  const localeKeys = useLocaleKeys();
  const {
    checkoutStore: {isPickupFlow, isPreselectedFlow},
  } = useControllerProps();

  return (
    <Step
      index={index!}
      title={localeKeys.checkout.place_order_fast_flow.delivery_method_title()}
      dataHook={DeliveryMethodStepDataHook.root}
      emptyComponent={DeliveryMethodEmpty}
      openComponent={DeliveryMethodOpen}
      collapsedComponent={DeliveryMethodCollapsed}
      hideEditButton={isPickupFlow || isPreselectedFlow}
    />
  );
};
