import React from 'react';
import {MemberInfoBox} from '../MemberInfoBox/MemberInfoBox';
import {ExpressCheckoutButtons} from '@wix/ecom-platform-express-checkout-buttons';
import {useControllerProps} from '../Widget/ControllerContext';
import {StepsManager} from '../CheckoutSteps/StepsManager/StepsManager';
import {CustomerDetailsStep} from '../CheckoutSteps/Steps/CustomerDetailsStep/CustomerDetailsStep';
import {PaymentStep} from '../CheckoutSteps/Steps/PaymentStep/PaymentStep';
import {PlaceOrderStep} from '../CheckoutSteps/Steps/PlaceOrderStep/PlaceOrderStep';
import {StepId} from '../../../types/app.types';
import {ErrorDialog} from '../ErrorDialog/ErrorDialog';
import {DeliveryMethodStep} from '../CheckoutSteps/Steps/DeliveryMethodStep/DeliveryMethodStep';
import {Violations} from '../Violations/Violations';

export enum FullFlowDataHook {
  root = 'FullFlow.root',
  title = 'FullFlow.title',
  expressCheckout = 'FullFlow.expressCheckout',
  violations = 'FullFlow.violations',
}

export const FullFlow = () => {
  const {
    checkoutStore: {updateCheckoutError, placeOrderError, checkout, shouldShowViolations, isShippingFlow},
    stepsManagerStore: {shouldDisplayExpressCheckout, stepsList, activeStep},
    memberStore: {isAddressesAppInstalled},
  } = useControllerProps();

  const isOnPlaceOrderStep = activeStep.stepId === StepId.placeOrder;
  const shouldShowViolationsTopBanner = shouldShowViolations && !isOnPlaceOrderStep;

  return (
    <div data-hook={FullFlowDataHook.root}>
      {shouldShowViolationsTopBanner && <Violations dataHook={FullFlowDataHook.violations} isTopBanner={true} />}
      {shouldDisplayExpressCheckout && (
        <div data-hook={FullFlowDataHook.expressCheckout}>
          <ExpressCheckoutButtons
            layout={'horizontal'}
            isShippable={isShippingFlow}
            estimatedTotal={`${checkout.payNowTotalAfterGiftCard?.amount}`}
          />
        </div>
      )}
      {isAddressesAppInstalled && <MemberInfoBox />}
      <StepsManager>
        {stepsList.map((step) => {
          /* istanbul ignore else */
          if (step.id === StepId.customerDetails) {
            return <CustomerDetailsStep key={StepId.customerDetails} />;
          } else if (step.id === StepId.payment) {
            return <PaymentStep key={StepId.payment} />;
          } else if (step.id === StepId.deliveryMethod) {
            return <DeliveryMethodStep key={StepId.deliveryMethod} />;
          } else if (step.id === StepId.placeOrder) {
            return <PlaceOrderStep key={StepId.placeOrder} />;
          } else {
            return null;
          }
        })}
      </StepsManager>
      {(checkout.errors.noItemsError || placeOrderError || updateCheckoutError) && <ErrorDialog />}
    </div>
  );
};
