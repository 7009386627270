import React from 'react';
import {ViolationSeverity} from '../../../domain/models/Violation.model';
import {SectionNotification, SectionNotificationType} from 'wix-ui-tpa';
import Alert from 'wix-ui-icons-common/on-stage/Alert';
import Error from 'wix-ui-icons-common/on-stage/Error';
import {SanitizeInnerHTMLWrapper} from '@wix/wixstores-client-common-components';
import {classes} from './Violations.st.css';

export enum ViolationDataHook {
  root = 'ViolationDataHook.root',
  message = 'ViolationDataHook.message',
}

export interface ViolationProps {
  severity: ViolationSeverity;
  description: string;
}

export const Violation = ({severity, description}: ViolationProps) => {
  const type = severity === ViolationSeverity.error ? SectionNotificationType.error : SectionNotificationType.alert;
  const Icon = severity === ViolationSeverity.error ? Error : Alert;
  const iconClassName = severity === ViolationSeverity.error ? classes.errorIcon : classes.alertIcon;

  return (
    <SectionNotification type={type} data-hook={ViolationDataHook.root} className={classes.violation}>
      <SectionNotification.Icon icon={<Icon className={iconClassName} />} />
      <SectionNotification.Text>
        <SanitizeInnerHTMLWrapper innerHtml={description}>
          <div data-hook={ViolationDataHook.message} />
        </SanitizeInnerHTMLWrapper>
      </SectionNotification.Text>
    </SectionNotification>
  );
};
